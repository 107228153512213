import { FilePdfFilled, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Skeleton, Table, Tooltip, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetReportRequest,
  GetReportRequestLayout,
  GetSurveyCampaign360TeamReportRequest,
  GetSurveyCampaignGroupReportResponse,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { getReport, getSurveyCampaign360TeamReport } from "../../services/api";
import { FeedbackGroupQuestionReportKeys } from "../../services/i8tn/FeedbackGroupQuestionReport/keys";
import { NormalGroupQuestionReportKeys } from "../../services/i8tn/NormalGroupQuestionReport/keys";
import { getQueryParam, setQueryParam } from "../../util";

interface Prop {
  report?: GetSurveyCampaignGroupReportResponse;
}

export default function FeedbackTeamViewV3(props: Prop) {
  const [teamReport, setTeamReport] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTeamReportLoading, setIsTeamReportLoading] = useState(false);
  const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false);
  const [teams, setTeams] = useState<string[]>([]);
  const [team, setTeam] = useState<string>(getQueryParam("team") || "");

  const { t } = useTranslation();

  const surveyCampaignId = getQueryParam("surveyCampaignId");

  useEffect(() => {
    try {
      setIsLoading(true);
      const req: GetSurveyCampaign360TeamReportRequest = {
        surveyCampaignId: surveyCampaignId,
      };
      getSurveyCampaign360TeamReport(req)
        .then((res) => {
          if (!res.success) throw new Error(res.message);
          if (!res.responseStatisticsByTeam) {
            return;
          }
          setTeams(Object.keys(res.responseStatisticsByTeam));
        })
        .catch((err) => {
          message.error(err);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));

      if (team) {
        setIsTeamReportLoading(true);
        const req: GetReportRequest = {
          surveyCampaignId: surveyCampaignId || "",
          isPDF: false,
          layout: GetReportRequestLayout.Team,
          team: team,
        };
        getReport(req)
          .then((res) => {
            setTeamReport(res?.data);
          })
          .catch((err) => {
            message.error(err);
          })
          .finally(() => setIsTeamReportLoading(false));
      }
    } catch (err) {
      message.error(
        typeof err === "string"
          ? err
          : typeof err === "object"
          ? err?.toString()
          : `Something went wrong`
      );
      setIsError(true);
    }
    setIsLoading(false);
  }, [surveyCampaignId, team]);

  const onExportPDF = () => {
    setIsPrintAsPDFLoading(true);
    const req: GetReportRequest = {
      surveyCampaignId: surveyCampaignId || "",
      isPDF: true,
      layout: GetReportRequestLayout.Team,
      team: getQueryParam("team"),
    };
    getReport(req)
      .catch((err) => {
        message.error(err);
      })
      .finally(() => setIsPrintAsPDFLoading(false));
  };

  if (isError) {
    return (
      <div>
        <h1>Oops. Invalid survey campaign</h1>
      </div>
    );
  }

  if (isLoading) {
    return <Skeleton active />;
  }

  const columns = [
    {
      title: <>Team</>,
      width: 200,
      dataIndex: "team",
      sorter: (
        a: { key: number; team: string },
        b: { key: number; team: string }
      ) => a.team.localeCompare(b.team),
    },
    {
      title: (
        <>
          {t(
            FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader
          )}
        </>
      ),
      render: (rowData: { key: number; team: string }) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            <Button
              type="link"
              onClick={() => {
                setQueryParam("team", rowData.team);
                setTeam(rowData.team);

                setIsTeamReportLoading(true);
                const req: GetReportRequest = {
                  surveyCampaignId: surveyCampaignId || "",
                  isPDF: false,
                  layout: GetReportRequestLayout.Team,
                  team: rowData.team,
                };
                getReport(req)
                  .then((res) => {
                    setTeamReport(res?.data);
                  })
                  .catch((err) => {
                    message.error(err);
                  })
                  .finally(() => setIsTeamReportLoading(false));
              }}
            >
              <>
                {t(
                  FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink
                )}
              </>
            </Button>
          </div>
        );
      },
    },
  ];

  const dataSource = teams.map((t, i) => ({ key: i, team: t }));

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
      }}
    >
      <Card
        style={{
          width: "30%",
          minWidth: 400,
          overflow: "scroll",
        }}
      >
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </Card>
      <Card
        style={{
          width: "70%",
        }}
      >
        {isTeamReportLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
        ) : team ? (
          <Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gap: 20,
                marginBottom: 50,
              }}
            >
              {isPrintAsPDFLoading ? (
                <LoadingOutlined style={{ fontSize: 30 }} />
              ) : (
                <Tooltip
                  title={t(
                    NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip
                  )}
                >
                  <FilePdfFilled
                    data-testid={
                      TEST_IDS.REPORT_PAGE.group_download_as_pdf_button_id
                    }
                    onClick={(e) => {
                      onExportPDF();
                    }}
                    style={{ fontSize: 30 }}
                  />
                </Tooltip>
              )}
            </div>

            <div dangerouslySetInnerHTML={{ __html: teamReport }}></div>
          </Fragment>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={`Select a team from the left`}
          />
        )}
      </Card>
    </div>
  );
}

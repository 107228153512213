import { ReportsViewKeys } from "./keys";

export const englishValues = {
  [ReportsViewKeys.reportsView_reportsBreadcrumb]: "Reports",
  [ReportsViewKeys.reportsView_lastUpdatedString]: "Last updated",
  [ReportsViewKeys.reportsView_totalString]: "Total",
  [ReportsViewKeys.reportsView_completedString]: "Completed",
  [ReportsViewKeys.reportsView_cancelledString]: "Cancelled",
  [ReportsViewKeys.reportsView_completionRateString]: "Completion rate",
  [ReportsViewKeys.reportsView_groupReportTab]: "Group Report",
  [ReportsViewKeys.reportsView_individualsReportTab]: "Individuals Report",
  [ReportsViewKeys.reportsView_dimensionSummary]: "Dimension Summary",
  [ReportsViewKeys.reportsView_engagementSummary]: "Engagement Summary",
  [ReportsViewKeys.reportsView_questions]: "Questions",
  [ReportsViewKeys.reportsView_highlights]: "Highlights",
  [ReportsViewKeys.reportsView_legend]: "Legend",
  [ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage]:
    "Values are displayed in %",
  [ReportsViewKeys.reportsView_favourable]: "Favourable",
  [ReportsViewKeys.reportsView_neutral]: "Neutral",
  [ReportsViewKeys.reportsView_unfavourable]: "Unfavourable",
  [ReportsViewKeys.reportsView_notApplicable]: "(not applicable))",
  [ReportsViewKeys.reportsView_reportUnavailable]: "(report unavailable)",
};

export const malayValues = {
  [ReportsViewKeys.reportsView_reportsBreadcrumb]: "Laporan",
  [ReportsViewKeys.reportsView_lastUpdatedString]: "Dikemas kini pada",
  [ReportsViewKeys.reportsView_totalString]: "Jumlah",
  [ReportsViewKeys.reportsView_completedString]: "Dilengkapkan",
  [ReportsViewKeys.reportsView_cancelledString]: "Dibatalkan",
  [ReportsViewKeys.reportsView_completionRateString]: "Kadar pelengkapan",
  [ReportsViewKeys.reportsView_groupReportTab]: "Laporan Kumpulan",
  [ReportsViewKeys.reportsView_individualsReportTab]: "Laporan Individu",
  [ReportsViewKeys.reportsView_dimensionSummary]: "Ringkasan Dimensi",
  [ReportsViewKeys.reportsView_engagementSummary]: "Ringkasan Penglibatan",
  [ReportsViewKeys.reportsView_questions]: "Soalan",
  [ReportsViewKeys.reportsView_highlights]: "Sorotan",
  [ReportsViewKeys.reportsView_legend]: "Legenda",
  [ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage]:
    "Nilai dipaparkan dalam %",
  [ReportsViewKeys.reportsView_favourable]: "Menguntungkan",
  [ReportsViewKeys.reportsView_neutral]: "Neutral",
  [ReportsViewKeys.reportsView_unfavourable]: "Tidak Menguntungkan",
  [ReportsViewKeys.reportsView_notApplicable]: "(tidak berkenaan)",
  [ReportsViewKeys.reportsView_reportUnavailable]: "(laporan tidak tersedia)",
};
export const chineseSimplifiedValues = {
  [ReportsViewKeys.reportsView_reportsBreadcrumb]: "报告",
  [ReportsViewKeys.reportsView_lastUpdatedString]: "更新时间",
  [ReportsViewKeys.reportsView_totalString]: "总数",
  [ReportsViewKeys.reportsView_completedString]: "已完成",
  [ReportsViewKeys.reportsView_cancelledString]: "已取消",
  [ReportsViewKeys.reportsView_completionRateString]: "完成率",
  [ReportsViewKeys.reportsView_groupReportTab]: "组报告",
  [ReportsViewKeys.reportsView_individualsReportTab]: "个人报告",
  [ReportsViewKeys.reportsView_dimensionSummary]: "维度摘要",
  [ReportsViewKeys.reportsView_engagementSummary]: "参与度摘要",
  [ReportsViewKeys.reportsView_questions]: "问题",
  [ReportsViewKeys.reportsView_highlights]: "亮点",
  [ReportsViewKeys.reportsView_legend]: "图例",
  [ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage]:
    "值以百分比显示",
  [ReportsViewKeys.reportsView_favourable]: "有利",
  [ReportsViewKeys.reportsView_neutral]: "中立",
  [ReportsViewKeys.reportsView_unfavourable]: "不利",
  [ReportsViewKeys.reportsView_notApplicable]: "（不适用）",
  [ReportsViewKeys.reportsView_reportUnavailable]: "（报告不可用）",
};
export const chineseTraditionalValues = {
  [ReportsViewKeys.reportsView_reportsBreadcrumb]: "報告",
  [ReportsViewKeys.reportsView_lastUpdatedString]: "更新時間",
  [ReportsViewKeys.reportsView_totalString]: "總數",
  [ReportsViewKeys.reportsView_completedString]: "已完成",
  [ReportsViewKeys.reportsView_cancelledString]: "已取消",
  [ReportsViewKeys.reportsView_completionRateString]: "完成率",
  [ReportsViewKeys.reportsView_groupReportTab]: "組報告",
  [ReportsViewKeys.reportsView_individualsReportTab]: "個人報告",
  [ReportsViewKeys.reportsView_dimensionSummary]: "維度摘要",
  [ReportsViewKeys.reportsView_engagementSummary]: "參與度摘要",
  [ReportsViewKeys.reportsView_questions]: "問題",
  [ReportsViewKeys.reportsView_highlights]: "亮點",
  [ReportsViewKeys.reportsView_legend]: "圖例",
  [ReportsViewKeys.reportsView_valuesAreDisplayedInPercentage]:
    "值以百分比顯示",
  [ReportsViewKeys.reportsView_favourable]: "有利",
  [ReportsViewKeys.reportsView_neutral]: "中立",
  [ReportsViewKeys.reportsView_unfavourable]: "不利",
  [ReportsViewKeys.reportsView_notApplicable]: "（不適用）",
  [ReportsViewKeys.reportsView_reportUnavailable]: "（報告不可用）",
};
